import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { Entity } from '@backstage/catalog-model';
import { HeimdallTeam } from '@hashicorp/backstage-plugin-heimdall-backend';
import { createApiRef } from '@backstage/core-plugin-api';

export const heimdallApiRef = createApiRef<HeimdallApi>({
  id: 'plugin.heimdall.api',
});

export interface HeimdallClientOptions {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
}

export interface TeamDetailOptions {
  entity: Entity;
}

export interface HeimdallApi {
  getTeamDetails(options: TeamDetailOptions): Promise<HeimdallTeam>;
}

export class HeimdallClient implements HeimdallApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: HeimdallClientOptions) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getTeamDetails(options: TeamDetailOptions): Promise<HeimdallTeam> {
    const { entity } = options;
    const baseUrl = await this.discoveryApi.getBaseUrl('heimdall');
    const { token } = await this.identityApi.getCredentials();

    if (!entity) {
      return Promise.reject('No entity provided');
    }

    const resp = await fetch(`${baseUrl}/teams/${entity.metadata.name}`, {
      headers: token ? { Authorization: `Bearer ${token}` } : undefined,
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const result: HeimdallTeam = await resp.json();
    return result;
  }
}
