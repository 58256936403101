import {
  createPlugin,
  createApiFactory,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { heimdallApiRef, HeimdallClient } from './api';

export const heimdallPlugin = createPlugin({
  id: 'heimdall',
  apis: [
    createApiFactory({
      api: heimdallApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory({ discoveryApi, identityApi }) {
        return new HeimdallClient({ discoveryApi, identityApi });
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const HeimdallTeamSummary = heimdallPlugin.provide(
  createRoutableExtension({
    name: 'HeimdallTeamSummary',
    component: () =>
      import('./components/HeimdallTeamSummary').then(
        m => m.HeimdallTeamSummary,
      ),
    mountPoint: rootRouteRef,
  }),
);
